import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { ElementSwitch } from '../utils/cockpit-element-switch';

const Page = ({ data }) => {
  const { title, content, contentElements } = data.cockpitPage;
  return (
    <Layout smallHeader={true} title={title.value}>
      <SEO
        title={title.value}
        keywords={[`k9 kommando`, `gaming`, title.value]}
      />
      <h1 className="page-title">{title.value}</h1>
      {Boolean(content) && (
        <div dangerouslySetInnerHTML={{ __html: content.value }}></div>
      )}
      {Boolean(contentElements) && (
        <ElementSwitch elements={contentElements.value} />
      )}
    </Layout>
  );
};

export default Page;

// Query to be ran on build, passes resulting JSON as 'data' prop
export const query = graphql`
  query($id: String!) {
    cockpitPage(id: { eq: $id }) {
      title {
        value
      }
      contentElements {
        value {
          _id
          link
        }
      }
    }
  }
`;
